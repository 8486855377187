import React from 'react'
import logo from './money.png'
import css from './index.module.css'

const Logo = () => {
  return (
    <div className={css.component_logo}>
      <img src={logo}/>
      <h1>MyOnlzaim</h1>
    </div>

  )
}

export default Logo
